import { axiosWhoisIPCpApi } from '@axios'

export default axiosWhoisIPCpApi.defaults.baseURL

export async function ripeSearchByPrefix(prefix) {
  try {
    return axiosWhoisIPCpApi.get(`/ripe/db/whois/search/prefix/${prefix}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
  } catch (e) {
    throw new Error(e)
  }
}
