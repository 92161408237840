import { axiosRoutinatorApi } from '@axios'

export default axiosRoutinatorApi.defaults.baseURL

export async function getRPKIByPrefixAndAsnApi(asn, prefix) {
  try {
    return axiosRoutinatorApi.get(`/validity/${asn}/${prefix}`, {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
      },
    })
  } catch (e) {
    throw new Error(e)
  }
}

export async function getTestRipe(prefix) {
  try {
    return axiosRoutinatorApi.get(`https://rest.db.ripe.net/search.json?abuse-contact=true&ignore404=true
    &managed-attributes=true&resource-holder=true&flags=r&offset=0&limit=20&query-string=${prefix}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
  } catch (e) {
    throw new Error(e)
  }
}

/* export async function getTestRipe(prefix) {
  try {
    return axiosRoutinatorApi.get(`https://ipcp.ipmen.network:8092/whoismen/api/v1/ripe/db/whois/search/prefix/${prefix}`, {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
  } catch (e) {
    throw new Error(e)
  }
} */
