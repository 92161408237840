import { axiosBgpApi } from '@axios'

export default axiosBgpApi.defaults.baseURL

export async function getBgpByPrefixApi(prefix) {
  try {
    return await axiosBgpApi.get(`/prefix/${prefix}/search`)
  } catch (e) {
    throw new Error(e)
  }
}
