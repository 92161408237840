<template>
  <section id="dashboard-analytics">
    <b-container>
      <b-row>
        <b-col
          sm="5"
          xs="12"
        >
          <b-card>
            <b-card-header>
              <b-card-title>Networks</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-form-textarea
                id="ta-prefixes"
                v-model="taPrefixes"
                placeholder="Enter a list of networks, each on a new line."
                rows="5"
              />
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          sm="7"
          xs="12"
        >
          <b-card>
            <b-card-header>
              <b-card-title>Actions</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-button-toolbar justify>
                <b-button-group
                  class="mr-2 mb-1"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    title="Run collect"
                    @click="startCollectPrefixesInfo"
                  >
                    <feather-icon
                      icon="PlayIcon"
                      size="21"
                      class="stroke-current"
                    />
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="arrPrefixesInfo.length > 0">
        <b-col cols="12">
          <b-card>
            <b-card-header>
              <b-card-title>Result collect</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-table
                :items="arrPrefixesInfo"
                :fields="prefixInfoFields"
                striped
                responsive
              >
                <template #cell(show_details)="row">
                  <b-form-checkbox
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="row.toggleDetails"
                  >
                    <span class="vs-checkbox">
                      <span class="vs-checkbox--check">
                        <i class="vs-icon feather icon-check" />
                      </span>
                    </span>
                    <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
                  </b-form-checkbox>
                </template>

                <template #row-details="row">
                  <b-card>
                    <b-row class="mb-2">
                      <b-col
                        md="12"
                        class="mb-1"
                      >
                        <strong>BGP : </strong>
                        <json-viewer
                          :value="row.item.bgp"
                          copyable
                          boxed
                        />
                      </b-col>
                      <b-col
                        md="12"
                        class="mb-1"
                      >
                        <strong>RPKI : </strong>
                        <json-viewer
                          :value="row.item.rpki"
                          copyable
                          boxed
                        />
                      </b-col>
                      <b-col
                        md="12"
                        class="mb-1"
                      >
                        <strong>WHOIS : </strong>
                        <json-viewer
                          :value="row.item.whois"
                          copyable
                          boxed
                        />
                      </b-col>
                    </b-row>

                    <b-button
                      size="sm"
                      variant="outline-secondary"
                      @click="row.toggleDetails"
                    >
                      Hide Details
                    </b-button>
                  </b-card>
                </template>
                <template #cell(asn_bgp)="data">
                  {{ computeASn(data.item.bgp.meta[0].originASNs) }}
                </template>
                <template #cell(rpki_state)="data">
                  {{ computeRpkiState(data.item.rpki) }}
                </template>
                <template #cell(route_obj)="data">
                  {{ computeRouteObj(data.item.whois) }}
                </template>
                <template #cell(whois_org)="data">
                  {{ computeInetneumObjOrg(data.item.whois) }}
                </template>
                <template #cell(whois_c)="data">
                  {{ computeInetneumObjCountry(data.item.whois) }}
                </template>
                <template #cell(ripe_info)="data">
                  <b-button
                    size="sm"
                    variant="outline-secondary"
                    @click="$window.open(
                      `https://rest.db.ripe.net/search.json?abuse-contact=true&ignore404=true
    &managed-attributes=true&resource-holder=true&flags=r&offset=0&limit=20&query-string=${data.item.bgp.prefix}`,
                    ).focus()"
                  >Show RIPE
                  </b-button>
                </template>
              </b-table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BButtonToolbar,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BContainer,
  BFormCheckbox,
  BFormTextarea,
  BRow,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import JsonViewer from 'vue-json-viewer'
import { getBgpByPrefixApi } from '@/libs/api/api-bgp'
import { getRPKIByPrefixAndAsnApi } from '@/libs/api/routinator-api'
import { ripeSearchByPrefix } from '@/libs/api/whois-api'
/* import useDashboardIpamUtilsHandler from '@/views/ipam/ip-utils/useDashboardIpamUtilsHandler' */

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormTextarea,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    BTable,
    BFormCheckbox,
    JsonViewer,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      taPrefixes: '158.46.180.1/24\n5.104.72.0/24',
      arrPrefixesInfo: [],
      isStartedCollect: false,
      prefixInfoFields: [
        'show_details',
        { key: 'prefix', label: 'Prefix' },
        { key: 'asn_bgp', label: 'origin ASns BGP' },
        { key: 'rpki_state', label: 'RPKI State' },
        { key: 'ripe_info', label: 'RIPE INFO' },
        { key: 'route_obj', label: 'Route Object' },
        { key: 'whois_org', label: 'Whois Org' },
        { key: 'whois_email', label: 'Whois Email' },
        { key: 'whois_c', label: 'Whois Country' },
      ],
    }
  },
  beforeMount() {
  },
  showRipeInfo(prefix) {
    window.open(
      `https://rest.db.ripe.net/search.json?abuse-contact=true&ignore404=true
    &managed-attributes=true&resource-holder=true&flags=r&offset=0&limit=20&query-string=${prefix}`,
    ).focus()
  },
  methods: {
    computeASn(data) {
      return Array.isArray(data) ? data.join(', ') : data
    },
    computeRpkiState(data) {
      return data !== null ? data.validated_route.validity.state : ''
    },
    computeRouteObj(data) {
      let res = ''
      const object = this.getObjectRipeByType(data, 'route')
      if (object != null) {
        res = `${this.getAttributeRipe(object.attributes, 'route')}:
        ${this.getAttributeRipe(object.attributes, 'origin')}:
        ${this.getAttributeRipe(object.attributes, 'mnt-by')}`
      }
      return res
    },
    computeInetneumObjOrg(data) {
      let res = ''
      const object = this.getObjectRipeByType(data, 'inetnum')
      if (object != null) {
        res = `${this.getAttributeRipe(object.attributes, 'org')}`
      }
      return res
    },
    computeInetneumObjCountry(data) {
      let res = ''
      const object = this.getObjectRipeByType(data, 'inetnum')
      if (object != null) {
        res = `${this.getAttributeRipe(object.attributes, 'country')}`
      }
      return res
    },
    getAttributeRipe(attrs, nameAttr) {
      // eslint-disable-next-line no-restricted-syntax
      for (const attr of attrs.attribute) {
        if (attr.name === nameAttr) {
          return attr.value
        }
      }
      return null
    },
    getObjectRipeByType(data, type) {
      // eslint-disable-next-line no-restricted-syntax
      if (data !== null && data.objects.object !== null) {
        // eslint-disable-next-line no-restricted-syntax
        for (const object of data.objects.object) {
          if (object.type === type) {
            return object
          }
        }
      }
      return null
    },
    async startCollectPrefixesInfo() {
      this.isStartedCollect = true
      this.arrPrefixesInfo = []
      await this.collectInfoByAllPrefixes(this.taPrefixes.split('\n'))
      this.isStartedCollect = false
    },
    async collectInfoByAllPrefixes(prefixes) {
      const result = []
      /* eslint-disable-next-line */
      for (const p of prefixes) {
        result.push(this.collectInfoByPrefix(p))
      }
      await Promise.all(result).then(r => {
        r.forEach(q => this.arrPrefixesInfo.push(q))
      })
    },
    async collectInfoByPrefix(prefix) {
      const bgp = await this.getBgpByPrefix(prefix)
      const prefixStable = bgp.prefix
      let rpki = null
      const entries = Object.values(bgp.meta)
      let asn = null
      // eslint-disable-next-line no-restricted-syntax
      for (const meta of entries) {
        if (meta.sourceType === 'bgp') {
          if (meta.originASNs !== null && meta.originASNs[0] !== null) {
            // eslint-disable-next-line prefer-destructuring
            asn = meta.originASNs[0]
          }
        }
      }
      rpki = await this.getRPKIByPrefixAndAsn(asn, prefixStable)
      const whois = await this.getWhois(prefixStable)
      return {
        prefix,
        bgp,
        rpki,
        whois,
      }
    },
    async getBgpByPrefix(prefix) {
      const result = await getBgpByPrefixApi(prefix)
      return result !== null && result.status === 200 ? result.data.result : null
    },
    async getRPKIByPrefixAndAsn(asn, prefix) {
      const result = await getRPKIByPrefixAndAsnApi(asn, prefix)
      /* const resultRipe = await getTestRipe(prefix)
      console.log(resultRipe) */
      return result !== null && result.status === 200 ? result.data : null
    },
    async getWhois(prefix) {
      const result = await ripeSearchByPrefix(prefix)
      /* const resultRipe = await getTestRipe(prefix)
      console.log(resultRipe) */
      return result !== null && result.status === 200 ? result.data : null
    },
  },
  /* setup(props, { emit }) {
    const {} = useDashboardIpamHandler(toRefs(props), emit)

    return {}
  }, */
}
</script>
